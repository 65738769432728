import React from "react";
import styled from "styled-components";
import {Link} from "gatsby";

import SEO from "../components/seo";

import Colors from "../constants/colors";
import Fonts from "../constants/fonts";
import Breakpoints from "../constants/breakpoints";

import bgImage from "../images/404/bg-404_components.png";
import logo from "../images/icons/icon-missing_logo.svg";
import IconHome from "../images/icons/icon-home.svg";
import IconHomeHover from "../images/icons/icon-home-hover.svg";


const Container = styled.div`
  display: grid;
  grid-template-columns: auto 320px auto;
  grid-template-rows: 80px auto min-content auto 80px;
  height: 100vh;
  background-position: 50%;
  background-color: ${Colors.Background.home};
  background-image: url(${bgImage});
  background-size: 1330px 910px;
  background-repeat: repeat;
  overflow: hidden;

  @media only screen and (max-width: ${Breakpoints.Width.tablet_small}) {
    justify-items: center;
  }

  @media only screen and (max-height: ${Breakpoints.Height.home_minimun}) {
    height: auto;
  }
`;

const TitleSection = styled.div`
  padding-left: 116px;
  background-image: url(${logo});
  background-repeat: no-repeat;
  height: 96px;
`;

const Card = styled.div`
  grid-column: 2 / 3;
  grid-row: 3 / 4;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  padding: 32px;
  background: ${Colors.Background.home_card};
  border-radius: 10px;

  .card {
    &__title {
      text-transform: uppercase;
      color: ${Colors.Basic.orange_01};
      font-family: ${Fonts.Font_family.title_condensed};
      font-weight: normal;
      font-size: 64px;
      line-height: 64px;
      margin-bottom: 0;
      margin-top: 4px;
    }
  
    &__subtitle {
      text-transform: uppercase;
      color: ${Colors.Text.home_card_subtitle};
      font-family: ${Fonts.Font_family.title_condensed};
      font-weight: normal;
      font-size: 24px;
      line-height: 24px;
      margin-bottom: 0;
    }

    &__separator {
      width: 100%;
      height: 1px;
      margin: 32px auto;
      border-bottom: 1px solid ${Colors.Separator.home_card};
    }

    &__description {
      color: ${Colors.Text.home_card_description};
      font-size: 1rem;
      /* text-align: center; */
      margin-bottom: 0;
    }

    &__social {
      display: flex;
    }
  }
`;

const LinkHome = styled(Link)`
  font-family: ${Fonts.Font_family.title_condensed};
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  letter-spacing: 0.8px;
  text-decoration: none;
  color: ${Colors.Basic.gray_88};
  padding: 25px 24px 23px 68px;
  margin-top: 32px;
  border-radius: 35px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  background-color: transparent;
  background-image: url(${IconHome});
  background-repeat: no-repeat;
  background-position: 20px 50%;
  transition: all 0.2s ease-in-out;
  align-self: center;

  &:hover, &:active, &:focus {
    color: ${Colors.Basic.orange_01};
    border: 1px solid ${Colors.Basic.orange_01};
    background-color: rgba(0, 0, 0, 0.05);
    background-image: url(${IconHomeHover});
    box-shadow: 0px 2px 2px rgba(54,54,54,0.5), 0px 4px 4px rgba(54,54,54,0.5), 0px 8px 8px rgba(54,54,54,0.5), 0px 16px 16px rgba(54,54,54,0.5);
  }
`;

const NotFoundPage = () => {
  return (
    <Container name="home" id="home">
      <SEO title="404: Not found" />
      <Card data-sal="fade" data-sal-delay="200" data-sal-duration="350">
        <TitleSection>
          <h1 className="card__title">404</h1>
          <h2 className="card__subtitle">Page not found</h2>
        </TitleSection>
        <div className="card__separator"></div>
        <p className="card__description">Sorry, but the page you are looking for has not been found. Try checking the URL or return to the homepage.</p>
        <LinkHome to="/">
          Back to Homepage
        </LinkHome>
      </Card>
    </Container>
  );
};

export default NotFoundPage
